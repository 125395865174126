;(function() {
  'use strict'

  Factory.$inject = ["ThemeContext", "ResponseFilter", "DatapackView"];
  angular.module('app.core').factory('Datapack', Factory)

  /* @ngInject */
  function Factory(ThemeContext, ResponseFilter, DatapackView) {
    function Datapack(survey) {
      this.survey = survey
      this.applyDefaults()
    }

    Datapack.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: null,
        surveyId: null,
        refCounts: {},
        mappings: {},
        views: [],
        filters: [],
        prefilters: [],
        theme: null,
        completesOnly: true,
        createdAt: null,
        updatedAt: null,
      })
    }

    Datapack.prototype.deserialize = function(data) {
      var self = this

      this.id = data.id
      this.surveyId = data.surveyId
      this.refCounts = data.refCounts
      this.mappings = data.mappings

      var subdata = _.isPlainObject(data.data) ? data.data : {}

      this.views = (subdata.views || []).map(function(data) {
        return new DatapackView().deserialize(data)
      })
      this.filters = (subdata.filters || []).map(function(data) {
        return new ResponseFilter(self.survey).deserialize(data)
      })
      this.prefilters = subdata.prefilters
      this.theme = new ThemeContext()
        .asSurveyDefault()
        .deserialize(subdata.theme || self.survey.themeContext)
      this.completesOnly = subdata.completesOnly

      this.createdAt = data.createdAt ? moment(data.createdAt) : null
      this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null
      this.applyDefaults()
      return this
    }

    Datapack.prototype.serialize = function(minimal) {
      var data = {}
      data.id = this.id
      data.surveyId = this.surveyId

      // when doing save checks or sending to the server we don't need to include all
      // of this bloat
      if (!minimal) {
        data.refCounts = _.cloneDeep(this.refCounts)
        data.mappings = _.cloneDeep(this.mappings)
      }

      var subdata = {}
      subdata.views = this.views.map(function(view) {
        return view.serialize()
      })
      subdata.filters = this.filters.map(function(filter) {
        return filter.serialize()
      })
      subdata.prefilters = this.prefilters.slice()
      subdata.theme = this.theme.serialize()
      subdata.completesOnly = this.completesOnly
      data.data = subdata

      data.createdAt = this.createdAt ? this.createdAt.toISOString() : null
      data.updatedAt = this.updatedAt ? this.updatedAt.toISOString() : null
      return data
    }

    Datapack.prototype.clone = function() {
      return new Datapack(this.survey).deserialize(this.serialize())
    }

    Datapack.prototype.copy = function(datapack) {
      this.deserialize(datapack.serialize())
    }

    return Datapack
  }
})()
