;(function() {
  'use strict'

  Service.$inject = ["$rootScope", "$q", "$http", "Datapack", "configService", "glAuthService"];
  angular.module('app.core').service('datapackService', Service)

  /* @ngInject */
  function Service(
    $rootScope,
    $q,
    $http,
    Datapack,
    configService,
    glAuthService
  ) {
    this.create = create
    this.get = get
    this.getCSV = getCSV
    this.save = save

    function create(surveyId) {
      var options = {
        method: 'POST',
        url: configService.getDatapackUrl(`/tasks`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          handler: 'App\\Task\\Handlers\\GenerateDataPackForSurvey',
          payload: {
            surveyId: surveyId,
          },
        }),
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return $q(function(resolve, reject) {
        $http(options)
          .then(function(resp) {
            var taskId = resp.data.id
            function checkStatus() {
              var options = {
                method: 'GET',
                url: configService.getDatapackUrl(`/tasks/${taskId}`),
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
              if (glAuthService.hasCredentials()) {
                options.headers.Authorization = 'Bearer ' + glAuthService.getToken() // prettier-ignore
              }

              $http(options)
                .then(function(resp) {
                  if (resp.data.status === 'SUCCESS') {
                    resolve(resp.data)
                  } else if (resp.data.status === 'ERROR') {
                    reject(resp.data)
                  } else {
                    setTimeout(checkStatus, 2000)
                  }
                })
                .catch(function(error) {
                  reject(error)
                })
            }
            checkStatus()
          })
          .catch(function(error) {
            reject(error)
          })
      })
    }

    function get(survey) {
      var options = {
        method: 'GET',
        url: configService.getDatapackUrl(`/datapacks/${survey.id}`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return $http(options).then(function(resp) {
        var datapack = new Datapack(survey).deserialize(resp.data)
        return datapack
      })
    }

    function getCSV(surveyId) {
      var url = configService.getDatapackUrl(`/datapacks/${surveyId}.glw`)
      var options = {
        method: 'GET',
      }
      return fetch(url, options)
        .then(function(resp) {
          return resp.arrayBuffer()
        })
        .then(function(buf) {
          // console.log('buf', buf)
          var str = pako.inflate(buf, { to: 'string' })
          // console.log('str', str)
          var csv = Papa.parse(str)
          // console.log('csv', csv)
          return csv.data
        })
    }

    function save(datapack) {
      datapack.saving = true
      var url = configService.getDatapackUrl(`/datapacks/${datapack.surveyId}`)
      var data = datapack.serialize(true)
      var options = {
        method: 'PUT',
        url: configService.getDatapackUrl(`/tasks`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return fetch(url, options).then(function() {
        $rootScope.$applyAsync(function() {
          datapack.saving = false
        })
      })
    }
  }
})()
